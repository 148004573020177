import React from 'react'
import { SectionTeamMembers } from '../../../types/sections'
import { Box, Heading, Image, Text } from '../../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { SECTION_MARGIN } from '../../../utils/constants'

function TeamMembers(props: SectionTeamMembers): JSX.Element {
  const { id, sectionId, headline, teamMembers } = props
  return (
    <Box as={'section'} id={sectionId ?? id} my={SECTION_MARGIN} py={SECTION_MARGIN} backgroundColor={'black'}>
      <Grid>
        <Row center={'xs'}>
          <Col xs={12} md={10} lg={8}>
            <Heading as={'h2'} type={'display'} textAlign={'center'} color={'white'}>
              {headline}
            </Heading>
          </Col>
        </Row>

        <Box mt={[5, 6, 7]}>
          <Row>
            {teamMembers.map((teamMember) => {
              return (
                <Col key={teamMember.id} xs={6} md={4}>
                  <Box mb={[6, 7, 8]}>
                    <Box mb={4} borderRadius={'small'} overflow={'hidden'}>
                      <Image
                        alt={teamMember.image.alt ?? teamMember.name}
                        title={teamMember.image.title ?? teamMember.name}
                        image={teamMember.image.gatsbyImageData}
                        style={{ aspectRatio: '1/1' }}
                        imgStyle={{ objectFit: 'cover' }}
                      />
                    </Box>
                    <Box>
                      <Heading as={'h3'} type={'heading4'} color={'white'}>
                        {teamMember.name}
                      </Heading>
                      {teamMember.role && (
                        <Box mt={2}>
                          <Text color={'white'} opacity={0.7} type={'small'}>
                            {teamMember.role}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Col>
              )
            })}
          </Row>
        </Box>
      </Grid>
    </Box>
  )
}

export default TeamMembers
